/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/


/* whites and blacks */

$black: #000;
$black-forest: #1a1a1a;
$gray-darkest: #25282a;
$white: #fff;
$ghost: transparent;

/* greys - lightest to darkest */

$wild-sand: #f6f6f6;
$mercury: #e3e3e3;
$concrete: #f3f3f3;
$alto: #cfcfcf;
$silver: #cccccc;
$gray: #929292;
$rolling-stone: #7f8283;
$mine-shaft: #333333;

/* blues - main highlights */

$boston-blue: #409abf;
$seagull: #87ceeb;
$cornflower-blue: #6195ed;
$azure-radiance: #009eff;
$scooter: #31bac3;

/* pinks */

$cannon-pink: #934d7e;
$shocking-pink: #f31bb2;
$hollywood-cerise: #ff00a2;
$cotton-candy: #ffade6;

/* purps */

$heliotrope: #df75ff;

/* yellows */

$wattle: #d8d846;
$starship: #eaf63c;
$primrose: #ecf1a7;

/* new colors test */
$indigo-dye: #08415c;
$madder-lake: #cc2936;
$slate-gray: #6b818c;
$cultured: #f6f7f8;
$turquoise-green: #bbe1c3;

