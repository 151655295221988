@import '../../static/styles/base/colors';
@import '../../static/styles/base/variables';

/* Global Button Styles */
a.animated-button:link, a.animated-button:visited {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 15px;
  color: #fff;
  font-size:14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: .08em;
  border-radius: 0;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
a.animated-button:link:after, a.animated-button:visited:after {
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.75s ease 0s;
  -moz-transition: all 0.75s ease 0s;
  -o-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
}
a.animated-button:link:hover, a.animated-button:visited:hover {
  color: #FFF;
  background: $madder-lake;
  text-shadow: none;
}
a.animated-button:link:hover:after, a.animated-button:visited:hover:after {
  height: 450%;
}
a.animated-button:link, a.animated-button:visited {
  position: relative;
  display: block;
  margin: 30px auto 0;
  padding: 14px 15px;
  color: #fff;
  font-size:14px;
  border-radius: 0;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: .08em;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}


a.animated-button.victoria-four {
  border: 2px solid $madder-lake;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
}
a.animated-button.victoria-four:after {
  background: $madder-lake;
  opacity: .5;
  -moz-transform: translateY(-50%) translateX(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) translateX(-50%) rotate(90deg);
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
  transform: translateY(-50%) translateX(-50%) rotate(90deg);
}
a.animated-button.victoria-four:hover:after {
  opacity: 1;
  height: 600% !important;
}
